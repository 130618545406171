body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  width: 100vw;
}

.header {
  padding: 1rem;
  margin: 0 auto;
  background-color: black;
}

.form {
  display: flex;
  flex-direction: column;

  @media (width < 600px) {
    width: 100vw;
  }
}

.form__input {
  margin: 0 auto;
  margin-bottom: 0.5rem;
  padding: 0.2rem;
  width: 70%;

  @media (width < 600px) {
    font-size: 1.4em;
    margin-bottom: 1rem;
    width: 80%;
    border: 1px solid black;
  }
}

.form__input--box {
  display: flex;

  @media (width < 600px) {
    flex-direction: column;
  }
}

.form__container {
  background-color: white;
  padding: 3rem;
  padding-top: 0rem;
  min-width: 30rem;
  width: min-content;
  margin: 0 auto;
  margin-top: 3rem;
  box-shadow: 5px 10px 25px 10px rgba(0,0,0,0.5);
  @media (max-width: 500px) {
    margin-top: 0;
    min-width: 100vw;
    padding: 0rem;
  }
}

.form__checkbox {
  display: flex;
  flex-direction: row;
  font-size: 0.6em;
  height: 1rem;
  margin: 0;
  align-items: start;
  text-align: start;

  @media (width < 600px) {
    font-size: 1em;
    height: 3rem;
  }
}

.form__checkbox > p {
  margin: 0;
}

.form__checkbox--box {

  @media (width < 600px) {
    padding: 1rem;
  }

}

.contest__rules {
  font-size: .8em;

  @media (width < 600px) {
    font-size: 1em;
  }
}

.header__image {
  @media (max-width: 700px) {
    width: 15rem;
  }
}

.display__image {
  height: 10rem;
  margin: 0 auto;
}

.button {
  color: white;
  background-color: black;
  border: none;
  padding: 0.5rem 1rem;
  margin-top:  1rem;

  @media (width < 500px) {
    padding: 0.8rem 1rem;
    font-size: 1.4em;
    width: 100vw;
  }
}

.button:hover {
  cursor: pointer;
}

.button__img {
  color: white;
  background-color: black;
  border: none;
  padding: 0.5rem 1rem;
  margin-top:  1rem;
}

.button__img:hover {
  cursor: pointer;
}

.footer {
  clear: both;
  position: fixed;
  left: 0;
  top: 6rem;
  width: 100%;
  z-index: -10000;
  overflow: hidden;
  @media (max-width: 700px) {
    top: 4rem;
  }
  @media (max-width: 600px) {
    display: none;
  }
}

